import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import keycloak from '../keycloak';
import { convertISOToDate } from './misc/HelpersDate';

const useTableState = () => {
  const [keycloakState] = useState(keycloak);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  const [errorState, setErrorState] = useState({ visible: false, title: '', text: '' });  //edit form

  const showError = (title, textObj) => {
    const text = 'data' in textObj ? `${textObj.data.error } ${ textObj.data.message}` : textObj;
    setErrorState((prev) =>({...prev, visible: true, title, text }));
  };
  const hideError = () => {
    setErrorState((prev)=> ({ ...prev, visible: false }));
  };

  const [obj, setObj] = useState({});  //edit form
  const [tableState, setTableState] = useState({
    status: 'loading',
    data: [],
    error: '',
    loading: true,
    pages: -1,
    page:0,
    pageSize:10,
    sorted: [],
    filtered: []
  });

  const handleFieldChange = (event) => {
    const { type, name, value, checked } = event.target;
    let updatedValue = type === 'checkbox' ? checked : value;
    if (type === 'date') updatedValue = convertISOToDate(value);
    setObj((prev) => ({...prev, [name]: updatedValue }));
  };

  return [
    keycloakState,
    modal, setModal,
    toggleModal,
    errorState, setErrorState,
    showError, hideError,
    obj, setObj, handleFieldChange,
    tableState, setTableState
  ];
};
export default useTableState;

export const ErrorWindow = ({errorState, hideModal}) => {
  return (
  <>
    <Modal isOpen={errorState.visible} toggle={hideModal}>
      <ModalHeader toggle={hideModal}>{errorState.title}</ModalHeader>
      <ModalBody>
        {errorState.text}
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={hideModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  </>);
};
ErrorWindow.propTypes = {
    errorState: PropTypes.shape({
      visible: PropTypes.bool,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  hideModal: PropTypes.func,
};