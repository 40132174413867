// eslint-disable-next-line no-unused-vars
const prod = {
  url: {
    KEYCLOAK_BASE_URL: 'https://cloak.sitecenter.org',
    API_BASE_URL: 'https://api.sitecenter.org',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x',
  }
}

// eslint-disable-next-line no-unused-vars
const dev = {
  url: {
    KEYCLOAK_BASE_URL: 'https://cloak.sitecenter.org',
    API_BASE_URL: 'http://localhost:8195',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x',
  },
};

// export default config;

// export const
// eslint-disable-next-line import/prefer-default-export
export const config = prod
//export const config = process.env.NODE_ENV === 'development' ? dev : prod
// const config = process.env.NODE_ENV === 'development' ? dev : prod
// export default config