import React from 'react';
import { Button, Modal } from 'semantic-ui-react'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


const ErrorModal = (state) => {
  return (
    <Modal isOpen={state.visible} >
      <ModalHeader>{state.title}</ModalHeader>
      <ModalBody>
        {state.text}
      </ModalBody>
      <ModalFooter>
        <Button color="primary">
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ErrorModal;
