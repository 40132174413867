import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useTableState from '../useTableState';
import { websiteApi } from '../api/infra/WebsitesApi';


const WebsiteSelect = ({ websiteUuid, setWebsiteUuid, handleWebsiteCallback }) => {
  const [keycloakState] = useTableState();
  const [websites, setWebsites] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState('');
  const handleWebsiteChange = (value) => {
    setWebsiteUuid(value.uuid);
    setSelectedWebsite(value);
    if (handleWebsiteCallback !== undefined) handleWebsiteCallback(value);
  };
  useEffect(() => {
    websiteApi.list(keycloakState.token).then((value) => {
      setWebsites(value.data);
      if (websiteUuid === undefined || websiteUuid === '')
        if (value.data.length > 0) {
          setSelectedWebsite(value.data[0]);
          setWebsiteUuid(value.data[0].uuid);
        }
    });
  }, []);

  return (
    <Select
      name='website'
      onChange={handleWebsiteChange}
      options={websites} // here
      value={selectedWebsite}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.uuid}
      clearable={false}
    />
  );
};
WebsiteSelect.propTypes = {
  websiteUuid: PropTypes.string,
  setWebsiteUuid: PropTypes.func,
  handleWebsiteCallback: PropTypes.func
};
export default WebsiteSelect;