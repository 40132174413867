/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
// eslint-disable-next-line no-unused-vars
import LoginPage from './LoginPage';

const App = () => {
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  // eslint-disable-next-line no-unused-vars
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <Loader />;
  }

   if (!keycloak.authenticated) {
     keycloak.login()
     // return <LoginPage />;
   }

  return (
    <Suspense fallback={<Loader />}>
        <div
          className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
          dir={direction ? 'rtl' : 'ltr'}>
          <ThemeSelector />
          {routing}
        </div>
    </Suspense>
  );
};

export default App;
