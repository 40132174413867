import axios from 'axios'
import {config} from '../../../Constants'

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error.response.status === 404) {
    return { status: error.response.status };
  }
  return Promise.reject(error.response);
});

// -- Helper functions

function bearerAuth(token) {
  return `Bearer ${token}`
}
function list(token) {
  return instance.get('/api/cms/website/list/', {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function get(uuid, token) {
  return instance.get(`/api/cms/website/${uuid}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function create(obj, token) {
  return instance.post('/api/cms/website', obj, {
    headers: {'Content-Type': 'application/json', 'Authorization': bearerAuth(token) }
  })
}

function update(uuid, obj, token) {
  return instance.put(`/api/cms/website/${uuid}`, obj, {
    headers: {'Content-Type': 'application/json', 'Authorization': bearerAuth(token) }
  })
}

function save(obj, token) {
  const {uuid} = obj;
  if (uuid.length > 0)
    return update(uuid,obj, token);
  return create(obj, token);
}


function remove(uuid, token) {
  return instance.delete(`/api/cms/website/${uuid}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

// eslint-disable-next-line import/prefer-default-export
export const websiteApi = {
  list,
  get,
  create,
  update,
  save,
  remove
}

// -- Axios
