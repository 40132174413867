// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Select from 'react-select';

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText,
  // eslint-disable-next-line no-unused-vars
  Button, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import ReactTable from 'react-table-v6';

import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
// eslint-disable-next-line no-unused-vars
import { msgGroupTypeApi } from '../../../components/api/msg/MsgGroupTypeApi';
import { msgGroupApi } from '../../../components/api/msg/MsgGroupApi';

import { handleLogError } from '../../../components/misc/Helpers';
// eslint-disable-next-line no-unused-vars
import { convertDateToISO, convertISOToDate } from '../../../components/misc/HelpersDate';
import useTableState from '../../../components/useTableState';

import { userGroupsApi } from '../../../components/api/user/UserGroupsApi';
import { rolesApi } from '../../../components/api/user/RoleApi';

const MsgGroupEditForm = () => {
  const [
    // eslint-disable-next-line no-unused-vars
    keycloakState, modal, setModal, toggleModal, errorState, setErrorState, showError, hideError, obj, setObj, handleFieldChange, tableState, setTableState,
  ] = useTableState();

  const [activeTab, setActiveTab] = useState('1');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { uuid } = useParams();
  const [websiteUuid, setWebsiteUuid] = useState('');
  const [groupTypes, setGroupTypes] = useState([]);

  const [msgGroups, setMsgGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleGetRow = async () => {
    try {
      await msgGroupApi.get(uuid, keycloakState.token).then(r => {
        setObj(r.data);
        setWebsiteUuid(r.data.websiteUuid);
      });
      setLoading(false);
    } catch (error) {
      handleLogError(error);
    }
  };
  useEffect(() => {
    handleGetRow();
  }, []);
  useEffect(() => {
    msgGroupTypeApi.listByWebsite(websiteUuid, keycloakState.token).then((value) => setGroupTypes(value.data));
    userGroupsApi.listByWebsite(websiteUuid, keycloakState.token).then((value) => setUserGroups(value.data));
    rolesApi.listByWebsite(websiteUuid, keycloakState.token).then((value) => setRoles(value.data));
    msgGroupApi.listByWebsite(websiteUuid, keycloakState.token).then((value) => setMsgGroups(value.data));
  }, [websiteUuid]);

  const handleMsgGroupTypeChange = (value) => {
    if (value !== null)
      setObj((prev) => ({ ...prev, msgGroupTypeId: value.id, msgGroupTypeUuid: value.uuid }));
    else
      setObj((prev) => ({ ...prev, msgGroupTypeId: "", msgGroupTypeUuid: "" }));
  };

  const handleMsgGroupParentChange = (value) => {
    if (value !== null)
      setObj((prev) => ({ ...prev, parentId: value.id, parentUuid: value.uuid, parentName: value.name }));
    else
      setObj((prev) => ({ ...prev, parentId: "", parentUuid: "", parentName: "" }));
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    await msgGroupApi.update(uuid, obj, keycloakState.token);
    navigate('/admin/msg/group');
  };

  // --- Properties modal --------------------------------------------------------------------------
  const [objProp, setObjProp] = useState({});

  const [modalProp, setModalProp] = useState(false);
  const toggleModalProp = () => {
    setModalProp(!modalProp);
  };

  const handleMsgGroupPropFieldChange = (event) => {
    const { type, name, value, checked } = event.target;
    let updatedValue = type === 'checkbox' ? checked : value;
    if (type === 'date') updatedValue = convertISOToDate(value);
    setObjProp((prev) => ({ ...prev, [name]: updatedValue }));
  };
  const handleSubmitProp = async (event) => {
    event.preventDefault();
    setObj((prev) => ({
          ...prev, groupProps:
            prev.groupProps.some(o => {
              return o.id === objProp.id;
            }) ?
              prev.groupProps.map(
                (a) => {
                  if (a.id === objProp.id)
                    return ({ ...a, ...objProp});
                  return a;
                }) : [...prev.groupProps, objProp],
        }
      ),
    );

    setModalProp(false);
  };

  // ===============================================================================================

  // --- Roles modal --------------------------------------------------------------------------
  const [objRole, setObjRole] = useState({});

  const [modalRole, setModalRole] = useState(false);
  const toggleModalRole = () => {
    setModalRole(!modalRole);
  };

  // eslint-disable-next-line no-unused-vars
  const handleUserGroupChange = (value) => {
    if (value !== null)
      setObjRole((prev) => ({ ...prev, userGroupId: value.id, userGroupUuid: value.uuid }));
    else
      setObjRole((prev) => ({ ...prev, userGroupId: "", userGroupUuid: "" }));
  };
  // eslint-disable-next-line no-unused-vars
  const handleRoleChange = (value) => {
    if (value !== null)
      setObjRole((prev) => ({ ...prev, roleId: value.id, roleUuid: value.uuid }));
    else
      setObjRole((prev) => ({ ...prev, roleId: "", roleUuid: "" }));
  };

  const handleMsgGroupRoleFieldChange = (event) => {
    const { type, name, value, checked } = event.target;
    let updatedValue = type === 'checkbox' ? checked : value;
    if (type === 'date') updatedValue = convertISOToDate(value);
    setObjRole((prev) => ({ ...prev, [name]: updatedValue }));
  };
  const handleSubmitRole = async (event) => {
    event.preventDefault();
    setObj((prev) => ({
          ...prev, groupRoles:
            prev.groupRoles.some(o => {
              return o.id === objRole.id;
            }) ?
              prev.groupRoles.map(
                (a) => {
                  if (a.id === objRole.id)
                    return ({ ...a, ...objRole});
                  return a;
                }) : [...prev.groupRoles, objRole],
        }
      ),
    );

    setModalRole(false);
  };

  // ===============================================================================================

  return (
    <>
      <BreadCrumbs />
      {/*--------------------------------------------------------------------------------*/}
      {/* Properties modal*/}
      {/*--------------------------------------------------------------------------------*/}
      {objProp.id !== undefined && (
        <Modal isOpen={modalProp} toggle={toggleModalProp.bind(null)}>
          <ModalHeader toggle={toggleModalProp.bind(null)}>Edit property</ModalHeader>
          <ModalBody>
            <Form onSubmit={(event) => handleSubmitProp(event)} id='editProp'>
              <Input type='hidden' name='id' id='id' defaultValue={objProp.id} />
              <FormGroup>
                <Label for='code'>Code</Label>
                <Input type='text' name='code' id='code' defaultValue={objProp.code}
                       onChange={handleMsgGroupPropFieldChange} />
              </FormGroup>
              <FormGroup>
                <Label for='name'>Name</Label>
                <Input type='text' name='name' id='name' defaultValue={objProp.name}
                       onChange={handleMsgGroupPropFieldChange} />
              </FormGroup>
              <FormGroup>
                <Label for='shortvalue'>Short value</Label>
                <Input type='text' name='shortvalue' id='shortvalue' defaultValue={objProp.shortvalue}
                       onChange={handleMsgGroupPropFieldChange} />
              </FormGroup>
              <FormGroup>
                <Label for='value'>Value</Label>
                <Input type='text' name='value' id='value' defaultValue={objProp.value}
                       onChange={handleMsgGroupPropFieldChange} />
              </FormGroup>
              <FormGroup>
                <Button color='primary' type='submit'>
                  Save
                </Button>&nbsp;
                <Button color='secondary' className='ml-1' onClick={toggleModalProp.bind(null)}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      )}
      {/*--------------------------------------------------------------------------------*/}
      {/* Roles modal*/}
      {/*--------------------------------------------------------------------------------*/}
      {objRole.id !== undefined && (
        <Modal isOpen={modalRole} toggle={toggleModalRole.bind(null)}>
          <ModalHeader toggle={toggleModalRole.bind(null)}>Edit message group roles</ModalHeader>
          <ModalBody>
            <Form onSubmit={(event) => handleSubmitRole(event)} id='editRole'>
              <Input type='hidden' name='id' id='id' defaultValue={objRole.id} />
              <FormGroup>
                <Label for='userGroups'>User group:</Label>
                {userGroups.length > 0 && obj !== undefined && (<Select
                  name='userGroups'
                  onChange={handleUserGroupChange}
                  options={userGroups} // here
                  defaultValue={userGroups.find((o) => o.uuid === objRole.userGroupUuid)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isClearable
                />)}
              </FormGroup>
              <FormGroup>
                <Label for='roles'>Role:</Label>
                {roles.length > 0 && obj !== undefined && (<Select
                  name='roles'
                  onChange={handleRoleChange}
                  options={roles} // here
                  defaultValue={roles.find((o) => o.uuid === objRole.roleUuid)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isClearable
                />)}
              </FormGroup>
              <FormGroup>
                <Label for='comment'>Comment</Label>
                <Input type='text' name='comment' id='comment' defaultValue={objRole.comment}
                       onChange={handleMsgGroupRoleFieldChange} />
              </FormGroup>
              <FormGroup>
                <Button color='primary' type='submit'>
                  Save
                </Button>&nbsp;
                <Button color='secondary' className='ml-1' onClick={toggleModalRole.bind(null)}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      )}

      <Form onSubmit={(event) => handleSubmit(event)} id='websiteForm' disabled={loading}>
        <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
        <Input type='hidden' name='uuid' id='uuid' defaultValue={obj.uuid} />
        <Row>
          {/*--------------------------------------------------------------------------------*/}
          {/* Highlighted Header Form                                                        */}
          {/*--------------------------------------------------------------------------------*/}
          <Col md='12'>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggleActiveTab('1')}>
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggleActiveTab('2')}>
                    Properties
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => toggleActiveTab('3')}>
                    Roles
                  </NavLink>
                </NavItem>
              </Nav>
              <CardTitle tag='h4' className='border-bottom bg-primary p-3 mb-0 text-white'>
                Edit group: {obj.name}
              </CardTitle>
              {/*<div>{JSON.stringify(obj)}</div>*/}
              <TabContent className='p-4' activeTab={activeTab}>
                <TabPane tabId='1'>
                  <CardBody>
                    <Row>
                      <Col>
                        <Row>
                          <Col md='4' className='text-end'>
                            Id:
                          </Col>
                          <Col md='8'>{obj.id}</Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            UUID:
                          </Col>
                          <Col md='8'>
                            {obj.uuid}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col md='4' className='text-end'>
                            Created:
                          </Col>
                          <Col md='8'>
                            {obj.dateCreated}
                          </Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            Modified:
                          </Col>
                          <Col md='8'>
                            <span>{obj.dateModified}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>

                  <CardBody className='bg-light'>
                    <CardTitle tag='h4' className='mb-0'>
                      Common Info
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <FormGroup>
                      <Label for='parentGroup'>Parent:</Label>
                      {msgGroups.length > 0 && obj !== undefined && (<Select
                        name='parentGroup'
                        onChange={handleMsgGroupParentChange}
                        options={msgGroups} // here
                        defaultValue={msgGroups.find((o) => o.uuid === obj.parentUuid)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                      />)}
                    </FormGroup>
                    <FormGroup>
                      <Label for='groupTypes'>Type:</Label>
                      {groupTypes.length > 0 && obj !== undefined && (<Select
                        name='groupTypes'
                        onChange={handleMsgGroupTypeChange}
                        options={groupTypes} // here
                        defaultValue={groupTypes.find((o) => o.uuid === obj.msgGroupTypeUuid)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                      />)}
                    </FormGroup>
                    <Row>
                      <Col md='12'>
                        <FormGroup>
                          <Label>Code</Label>
                          <Input type='text' name='code' placeholder='code' defaultValue={obj.code}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12'>
                        <FormGroup>
                          <Label>Name</Label>
                          <Input type='text' name='name' placeholder='Name' defaultValue={obj.name}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Url</Label>
                          <Input type='text' name='url' placeholder='url' defaultValue={obj.url}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Description:</Label>
                          <Input type='text' placeholder='' name='description' value={obj.description}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Sort</Label>
                          <Input type='text' name='sort' placeholder='sort' defaultValue={obj.sort}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>

                    </Row>
                  </CardBody>
                </TabPane>
                <TabPane tabId='2'>
                  <CardBody>
                    <Row>
                      <Row className="p-1">
                        <Col md='12'>
                          <Button color='primary' className='ml-1' onClick={() => {
                            let minId = -1;
                            if (obj.groupProps != null && obj.groupProps.length > 0) {
                              const minAttr = obj.groupProps.reduce(
                                (a, b) => {
                                  return a.id > b.id ? b : a;
                                },
                              );
                              if (minAttr == null || minAttr.id >= 0) minId = -1;
                              else minId -= minAttr.id;
                            }
                            setObjProp({ id: minId, code: '', name: '', value: '', shortvalue: '' });
                            setModalProp(true);
                          }}>
                            New
                          </Button>
                        </Col>
                      </Row>
                      {(obj.groupProps !== undefined) && (<ReactTable
                        columns={[
                          {
                            Header: 'Code',
                            accessor: 'code',
                            width: 150,
                          },
                          {
                            Header: 'Name',
                            accessor: 'name',
                            width: 250,
                          },
                          {
                            Header: 'Value',
                            accessor: 'shortvalue',
                            width: 250,
                          },
                          {
                            Header: 'Actions',
                            accessor: 'id',
                            sortable: false,
                            filterable: false,
                            Cell: e => {
                              return (
                                <div className="text-end">
                                  <Button
                                    color='primary'
                                    size='sm'
                                    round='true'
                                    icon='true'
                                    onClick={() => {
                                      // alert(id);
                                      const sobj = obj.groupProps.find((o) => o.id === e.original.id);
                                      setObjProp(sobj);
                                      setModalProp(true);
                                    }}
                                  >
                                    <i className='fa fa-edit' />Edit
                                  </Button>
                                  &nbsp;
                                  <Button className='ml-1' color='danger' size='sm' round='true' icon='true' ml
                                          onClick={async () => {
                                            setObj((prev) => ({
                                                ...prev, groupProps:
                                                  prev.groupProps.filter(
                                                    (a) => (a.id !== e.original.id))
                                              })
                                            );
                                          }}>
                                    <i className='fa fa-times' />Delete</Button>
                                </div>
                              );
                            },
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationBottom
                        className='-striped -highlight'
                        data={obj.groupProps}
                      />)
                      }
                    </Row>
                  </CardBody>
                </TabPane>
                <TabPane tabId='3'>
                  <CardBody>
                    <Row>
                      <Row className="p-1">
                        <Col md='12'>
                          <Button color='primary' className='ml-1' onClick={() => {
                            let minId = -1;
                            if (obj.groupRoles != null && obj.groupRoles.length > 0) {
                              const minAttr = obj.groupRoles.reduce(
                                (a, b) => {
                                  return a.id > b.id ? b : a;
                                },
                              );
                              if (minAttr == null || minAttr.id >= 0) minId = -1;
                              else minId -= minAttr.id;
                            }
                            setObjRole({ id: minId, roleId: '', roleUuid: '', roleName: '', roleCode: '', userGroupId: '',
                               userGroupUuid: '', userGroupName: '', comment: '', shortvalue: '' });
                            setModalRole(true);
                          }}>
                            New
                          </Button>
                        </Col>
                      </Row>
                      {(obj.groupRoles !== undefined) && (<ReactTable
                        columns={[
                          {
                            Header: 'User group',
                            accessor: 'userGroupName',
                            width: 250,
                          },
                          {
                            Header: 'Role',
                            accessor: 'roleName',
                            width: 250,
                          },
                          {
                            Header: 'Comment',
                            accessor: 'comment',
                            width: 250,
                          },
                          {
                            Header: 'Actions',
                            accessor: 'id',
                            sortable: false,
                            filterable: false,
                            Cell: e => {
                              return (
                                <div className="text-end">
                                  <Button
                                    color='primary'
                                    size='sm'
                                    round='true'
                                    icon='true'
                                    onClick={() => {
                                      // alert(id);
                                      const sobj = obj.groupRoles.find((o) => o.id === e.original.id);
                                      setObjRole(sobj);
                                      setModalRole(true);
                                    }}
                                  >
                                    <i className='fa fa-edit' />Edit
                                  </Button>
                                  &nbsp;
                                  <Button className='ml-1' color='danger' size='sm' round='true' icon='true' ml
                                          onClick={async () => {
                                            setObj((prev) => ({
                                                ...prev, groupRoles:
                                                  prev.groupRoles.filter(
                                                    (a) => (a.id !== e.original.id))
                                              })
                                            );
                                          }}>
                                    <i className='fa fa-times' />Delete</Button>
                                </div>
                              );
                            },
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationBottom
                        className='-striped -highlight'
                        data={obj.groupRoles}
                      />)
                      }
                    </Row>
                  </CardBody>
                </TabPane>
              </TabContent>

              <CardBody className='border-top gap-2 d-flex align-items-center'>
                <Button type='submit' className='btn btn-success' disabled={loading}>
                  Save
                </Button>
                <Link to='/admin/msg/group'>
                  <Button type='button' className='btn btn-dark ml-2'>
                    Cancel
                  </Button>
                </Link>
              </CardBody>

            </Card>
          </Col>
        </Row>
      </Form>
      {/*--------------------------------------------------------------------------------*/
      }
      {/*End Inner Div*/
      }
      {/*--------------------------------------------------------------------------------*/
      }
    </>
  )
    ;
};

export default MsgGroupEditForm;
