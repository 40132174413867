import moment from 'moment';

export const convertDateToISO = (vdate) => {
  if (vdate === null || vdate === undefined || vdate.length === 0) return "";

  if (vdate.indexOf("-") > 0) return vdate;

  const darr = vdate.split(".");    // ["25", "09", "2019"]
  const ISOFormat = new Date(parseInt(darr[2], 10),parseInt(darr[1],10)-1,parseInt(darr[0],10));
  return moment(ISOFormat).format('YYYY-MM-DD');
}
export const convertISOToDate = (vdate) => {
  if (vdate === null || vdate === undefined || vdate.length === 0) return "";

  if (vdate.indexOf(".") > 0) return vdate;

  const darr = vdate.split("-");    // ["2019", "09", "25"]
  const ISOFormat = new Date(parseInt(darr[0], 10),parseInt(darr[1],10)-1,parseInt(darr[2],10));
  return moment(ISOFormat).format('DD.MM.YYYY');
}
