const attrTypeData = [
  {id : 0, name : "UNKNOWN"},
  {id : 1, name : "STRING"},
  {id : 2, name : "LONG"},
  {id : 3, name : "DOUBLE"},
  {id : 4, name : "BLOB"},
  {id : 5, name : "FILE"},
  {id : 6, name : "URL"},
  {id : 7, name : "REF"},
];
export default attrTypeData ;
